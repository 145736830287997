import { isElementInViewport } from './utilities.js';

class CarouselComponent extends HTMLElement {
  constructor() {
    super();
    this.init();
    document.addEventListener('recommendations:loaded', this.init.bind(this));
  }

  init() {
    this.carousel = this.querySelector('[id^="Carousel-"]');
    this.carouselItems = this.querySelectorAll('.carousel__item');
    this.currentPageElement = this.querySelector('.carousel-counter--current');
    this.pageTotalElement = this.querySelector('.carousel-counter--total');
    this.prevButton = this.querySelector('button[name="previous"]');
    this.nextButton = this.querySelector('button[name="next"]');
    this.isDown = false;

    if (!this.carousel || !this.nextButton) return;

    this.initPages();

    const resizeObserver = new ResizeObserver(() => this.initPages());
    resizeObserver.observe(this.carousel);
    this.carousel.addEventListener('scroll', this.update.bind(this));
    this.prevButton.addEventListener('click', this.onButtonClick.bind(this));
    this.nextButton.addEventListener('click', this.onButtonClick.bind(this));
  }

  initPages() {
    this.carouselItemsToShow = Array.from(this.carouselItems).filter(
      element => element.clientWidth > 0
    );
    if (this.carouselItemsToShow.length < 2) return;

    this.carouselItemOffset =
      this.carouselItemsToShow[1].offsetLeft -
      this.carouselItemsToShow[0].offsetLeft;
    this.carouselItemsPerPage = Math.floor(
      (this.carousel.clientWidth - this.carouselItemsToShow[0].offsetLeft) /
        this.carouselItemOffset
    );
    this.totalPages =
      this.carouselItemsToShow.length - this.carouselItemsPerPage + 1;
    this.update();
  }

  update() {
    const previousPage = this.currentPage;
    this.currentPage =
      Math.round(this.carousel.scrollLeft / this.carouselItemOffset) + 1;

    if (this.currentPageElement && this.pageTotalElement) {
      this.currentPageElement.textContent = this.currentPage;
      this.pageTotalElement.textContent = this.totalPages;
    }

    this.carouselItems.forEach(item => {
      // Set aria-hidden based on the visibility of the slide
      const isVisible = isElementInViewport(item, 0.5); // 50% visibility threshold
      item.setAttribute('aria-hidden', !isVisible);
    });

    if (this.currentPage !== previousPage) {
      this.dispatchEvent(
        new CustomEvent('slideChanged', {
          detail: {
            currentPage: this.currentPage,
            currentElement: this.carouselItemsToShow[this.currentPage - 1]
          }
        })
      );
    }

    this.updateButtonsState();
  }

  updateButtonsState() {
    if (isElementInViewport(this.carouselItemsToShow[0])) {
      this.prevButton.setAttribute('disabled', 'disabled');
    } else {
      this.prevButton.removeAttribute('disabled');
    }

    if (
      isElementInViewport(
        this.carouselItemsToShow[this.carouselItemsToShow.length - 1]
      )
    ) {
      this.nextButton.setAttribute('disabled', 'disabled');
    } else {
      this.nextButton.removeAttribute('disabled');
    }
  }

  onButtonClick(event) {
    event.preventDefault();
    const step = event.currentTarget.dataset.step || 1;
    this.slideScrollPosition =
      event.currentTarget.name === 'next'
        ? this.carousel.scrollLeft + step * this.carouselItemOffset
        : this.carousel.scrollLeft - step * this.carouselItemOffset;
    this.carousel.scrollTo({
      left: this.slideScrollPosition,
      behavior: 'smooth'
    });
  }
}

customElements.define('carousel-component', CarouselComponent);

class TabbedCarouselComponent extends CarouselComponent {
  constructor() {
    super();
    this.init();
  }

  init() {
    this.tabMenu = this.querySelector('[id^="Tabs-"]');
    this.tabMenuItems = this.querySelectorAll('[id^="Tab-"]');
    this.lastMenuItem = this.querySelector('[id^="Tab-"]:last-child');

    this.tabMenuItems.forEach(item =>
      item.addEventListener('click', this.onTabClick.bind(this))
    );
    super.init();
  }

  update() {
    this.carouselItems.forEach(item => {
      // Check if the carousel item is within the viewport at least 50%
      if (isElementInViewport(item, 0.5)) {
        item.setAttribute('aria-hidden', 'false');
      } else {
        item.setAttribute('aria-hidden', 'true');
      }
      if (item.classList.contains('carousel-anchor')) {
        if (item.getBoundingClientRect().left <= 180) {
          if (this.tabMenuItems) {
            this.tabMenuItems.forEach(item =>
              item.classList.remove('is-active')
            );
            const id = item.id.split('Carousel-Item-')[1];
            const currentMenuItem = this.querySelector(`#Tab-${id}`);
            currentMenuItem.classList.add('is-active');
          }
        }
      }
    });
    if (this.lastMenuItem) {
      if (
        isElementInViewport(
          this.carouselItemsToShow[this.carouselItemsToShow.length - 1],
          0.5
        )
      ) {
        this.tabMenuItems.forEach(item => item.classList.remove('is-active'));
        this.lastMenuItem.classList.add('is-active');
      }
    }
    super.update();
  }
  onTabClick(event) {
    event.preventDefault();
    const id = event.target.id.split('Tab-')[1];
    const page = this.querySelector(`#Carousel-Item-${id}`);

    // Check if the screen width is less than 590px (mobile) or greater than or equal to 590px (desktop)
    const screenWidth = window.innerWidth;
    const leftOffset = screenWidth >= 590 ? 65 : 20;

    this.carousel.scrollTo({
      left: page.offsetLeft - leftOffset,
      behavior: 'smooth'
    });
  }
}

customElements.define('tabbed-carousel-component', TabbedCarouselComponent);
